
.Navbar{

  position: fixed !important;
}
.NavDropdown{

  color: blueviolet;
  background-color: blue;
}

