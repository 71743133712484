* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.carduri,
.products,
.agricultura,
.motorina,
.despre,.enGross,
.domiciliu,.contact,.recenzii,.cereale,.ingrasaminte {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

