@import url(https://fonts.googleapis.com/css?family=Roboto:400,900);
:root {
  --color:black;
  --background-image :"";
}

body{
  background-color:rgba(208, 204, 204, 0.597);
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: 
 
 var(--background-image) no-repeat center center fixed!important;
  opacity: 100%;
  
  
}


.text-center,.normal-text{
  font-family: 'Roboto', sans-serif !important;
  font-size: 27px !important;
  color: var(--color);


  
}

.fs-1{
  font-family: 'Roboto', sans-serif !important;
  color: var(--color);
  text-align: center;
}

.bg{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.bg1{
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
  margin: auto;
  z-index: -1;
}